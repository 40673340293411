import React from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
import UsageContent from "./usageContent";

function Usage() {
  return (
    <>
      <Header />
      <UsageContent />
      <Footer />
    </>
  );
}

export default Usage;
