export default function ReturnContent() {
  return (
    <>
      <div
        className="return-content container"
        style={{
          padding: "1.5rem 3rem",
        }}
      >
        <h3>سياسة استرداد العربون (تأمين دخول المزاد)</h3>
        <p>
          منصة مباشر للمزادات الإلكترونية تعمل بحيادية بين العملاء الراغبين في
          الشراء عبر المنصة وبين (الجهات الحكومية, الشركات, المؤسسات, الأفراد)
          اللذين يستخدمون منصة مباشر للمزادات الإلكترونية لعرض منتجاتهم سواء من
          عقارات، أو مركبات، أو معدات، أو أي أصل مخصص للبيع، وطريقة البيع في
          منصة مباشر تتم عن طريق المزادات الزمنية الالكترونية والمنقولة نقل
          مباشر والظرف المغلق, وتعتبر منصة مباشر للمزادات مزود خدمة تقنية للفئات
          المذكورة أعلاه.
        </p>
        <ol style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <li>
            <span className="fw-bold">الألفاظ والعبارات:</span>
            <ol type="i">
              <li>
                <span className="fw-bold">العربون:</span> هو عبارة عن التأمين
                المدفوع لدخول المزاد سواءً كان مالي أو سند لأمر.
              </li>
              <li>
                <span className="fw-bold">العربون المالي:</span> هو المبلغ
                النقدي الذي يتم دفعه من المشارك في المزاد ويعتبر جزء من قيمة
                المبيع وسُمي في منصة مباشر بمسمى التأمين.
              </li>
              <li>
                <span className="fw-bold">العربون كورقة مالية (سندلأمر):</span>{" "}
                هي الوثيقة التي تصدر من المشارك في المزاد لصالح شركة مباشر
                للتصميم عبر منصة نافذ التابعة لوزارة العدل والتي يلتزم بموجبها
                المشارك بدفع قيمة السند الصادر في حال التخلف عن سداد قيمة المبيع
                بعد ترسية المزاد عليه.
              </li>
            </ol>
          </li>
          <li>
            المشاركة في المزادات المقامة عبر منصة مباشر للمزادات تكون بناء على
            السياسات المعلنة على موقع مباشر للمزادات بالإضافة إلى الشروط
            والأحكام الخاصة بكل مزاد، وبالتالي فإن مستخدم منصة مباشر للمزادات
            يقر بدخوله في المزادات والمزايدة أنه قام بالاطلاع على السياسات
            والاحكام والشروط الخاصة بالمنصة والمعلن عنها في الموقع الرسمي لشركة
            مباشر وكذلك الشروط والاحكام الخاصة بكل مزاد المعلن عنها في النشرة
            التسويقية للمزاد ووافق عليها.
          </li>
          <li>
            منصة مباشر للمزادات مملوكة بالكامل لشركة مباشر وعند قيام العميل
            بالمشاركة في المزاد فإن عربون دخول المزاد يتم استيفاؤه بالطرق
            المتاحة للدفع عبر منصة مباشر للمزادات وهي: سداد عن طريق إيداعات- سند
            لأمر عن طريق نافذ, ويودع في حساب شركة مباشر ويتم رصده في محفظة
            المشارك.
          </li>
          <li>
            يجب على المشارك الاطلاع على الشروط والأحكام لكل مزاد قبل المشاركة في
            المزاد وذلك لكون شركة مباشر تُلزم المشارك الذي تمت ترسية المزاد عليه
            وتخلف عن السداد بإعادة المزاد على حسابه الشخصي ودفع الفارق بين قيمة
            المزاد الذي تمت ترسيته عليه وقيمة المزاد الذي تم إعادته.
          </li>
          <li>
            شركة مباشر لا تقدم أي ضمانات ولا تتحمل أي مسؤولية في حالة عدم التزام
            المستخدم بسياسة استخدام الموقع, ولا تتحمل المسؤولية عن أي مخاطرة، أو
            أضرار، أو تبعات، أو خسائر تقع على البائع أو المشتري أو أي طرف آخر
            وعلى من لحق به الضرر إبلاغنا بذلك من خلال التواصل على البريد الرسمي
            التالي: info@mobasher.sa وشرح الضرر الواقع عليه وستقوم شركة مباشر
            باتخاذ الإجراء حسب نوع الواقعة دون أي مسؤولية.
          </li>
          <li>
            الإجراءات اللاّزم اتخاذها من قبل شركة مباشر عند تعرضك لضرر تنحصر في:
            (توثيق وأرشفة الشكاوى والوقائع المخالفة والاحتفاظ بها لتقديمها
            للجهات ذات العلاقة في حال لزم الأمر في المبادرة لتقديمها أو تم طلبها
            في أي وقت) وتستطيع شركة مباشر تقديم جميع المعلومات التي تساعد على
            استرداد الحقوق بين المتعاقدين من خلال المنصة على ألا تؤثر تلك
            المعلومات على خصوصية الأطراف.
          </li>
          <li>
            <span className="fw-bold">طلب الاسترداد:</span>
            عند مشاركة العميل في مزاد ولم يحالفه الحظ بالفوز بالمزاد فإن شركة
            مباشر تعيد العربون متى ما قام العميل بطلب استرداد العربون عبر المنصة
            وذلك من خلال: (الدخول على المالية – محفظتي – طلب استرداد عربون)،
            ويتم تحويل العربون المالي أو فك سند لأمر خلال (3) ثلاثة أيام عمل من
            طلب الاسترداد في حال كانت جميع المعلومات المدونة والمرفقة في منصة
            مباشر صحيحة (شهادة مصرفية تثبت ملكية العميل للحساب وتتطابق مع
            المعلومات المدونة في المنصة) وغيرها من طلبات التحقق بشرط عدم ارتباط
            طالب الاسترداد بمزاد داخل المنصة أو عملية مالية تتطلب السداد.
          </li>
          <li>
            <span className="fw-bold">
              آلية استرداد العربون المالي لحساب المشارك في المزاد:
            </span>{" "}
            شركة مباشر تقوم بتحويل العرابين المالية المطلوب استردادها بناء على
            معلومات العميل المدونة في المنصة حيث إنها تستقبل الأموال عن طريق
            (إيداعات) ويتم ربط أسم المشارك بفاتورة السداد عند قيام المشارك بطلب
            المشاركة في المزاد, وليس لدى شركة مباشر صلاحية أو إطلاع على من قام
            بسداد فاتورة ايداعات وبالتالي فإنها تعتبر جميع الأموال الواردة من
            إيداعات عبر أرقام السداد التي تصدر من شركة مباشر تخص المشارك المسجل
            في منصة مباشر ويتم إعادة العربون المالي لحساب المشارك دون غيره,
            وشركة مباشر لا تحول الأموال لأشخاص آخرين حتى وإن طلب المشارك المسجل
            في المنصة ذلك.
          </li>
          <li>
            <span className="fw-bold">
              تحويل العرابين المالية للجهة البائعة:{" "}
            </span>
            <ol type="i">
              <li>
                في حال أن العميل شارك وفاز بالمزاد وكان المزاد خاص بجهات حكومية
                أو بجهات تم تشكيلها بقرار مجلس الوزراء أو إحدى الشركات المنبثقة
                من الوزارات والهيئات أو الشركات الكبرى المطروحة في سوق الأسهم
                ففي هذه الحالة تقوم شركة مباشر بتحويل العربون المالي إلى الجهات
                المذكورة أعلاه دون الرجوع للعميل, وتكون مواجهة العميل مع تلك
                الجهات في حال وجود خطاء أو عيب في المبيع، ومباشر للمزادات ليست
                إلا مطبقة لسياسات تلك الجهات، وبمشاركة العميل في تلك المزادات
                يخلي مسؤولية مباشر للمزادات من أي مطالبة مالية.
              </li>
              <li>
                في حال أن العميل شارك وفاز بالمزاد وكان المزاد خاص بشركات
                ومؤسسات القطاع الخاص أو كان خاص بأفراد ففي هذه الحالة تقوم شركة
                مباشر بإصدار فاتورة سداد بما تبقى من قيمة المبيع بالإضافة إلى
                السعي المستحق للمسوّق وتُستكمل فاتورة السداد بالعربون المالي
                الذي دفعه المشارك كتأمين دخول للمزاد, ومن ثم تتم إجراءات الإفراغ
                إلكترونيا بين البائع والمشتري ويتم تزويد شركة مباشر برقم السداد
                الخاص بالمبيع الذي تم بيعه ويتم السداد عن طريق منصة ناجز حفظاً
                لحقوق الطرفين أو أي طريقة معتمدة يراها طرفي العقد.
              </li>
            </ol>
          </li>
          <li>
            <span className="fw-bold">التخلف عن السداد:</span>
            <ol type="i">
              <li>
                <span className="text-decoration-underline">
                  في حال كان العربون مالي:
                </span>
                عند مشاركة العميل والفوز في مزاد خاص بشركات ومؤسسات القطاع الخاص
                أو كان خاص بإفراد، وكان العميل ليس لديه القدرة المالية على إكمال
                قيمة المبيع وتخلف عن السداد لأي ظرف خلال مدة السداد دون وجود عيب
                في المبيع فإنه يتم تطبيق نظام الوساطة العقارية وما جاء في لائحته
                التنفيذية فيما جاء من مصادرة العربون ومقدار العربون المسترد.
                <ul>
                  <li>
                    العربون المالي يعتبر مقدم ثمن المبيع لا يستحقه البائع إذا
                    فُسخ العقد من قبل البائع أو الوسيط العقاري أو وكيلة دون وجود
                    أسباب تعود على المشارك.
                  </li>
                  <li>
                    في حال تخلف المشارك عن السداد فيصبح العربون من حق مالك
                    العقار مالك العقار مالم يكن هناك اتفاق ينص على خلاف ذلك بين
                    مالك العقار والمسوق العقاري.
                  </li>
                </ul>
                <p className="my-3">
                  في حال تخلف المشارك عن السداد لظروف تخصه دون عيب في العقار ولم
                  يقدم طعنه في البيع أو اعتراضه خلال مدة ثلاثة أيام عمل فإن
                  لشركة مباشر الحق في تحويل كامل العربون إلى مالك العقار حسب
                  السياسة والاحكام التي تراها مناسبة لحفظ حقها، ويصبح من دفع
                  العربون في مواجهة المالك للعقار ويخلي مسؤولية شركة مباشر من
                  العربون، ولا تقوم شركة مباشر في البدء بإجراءات التحويل إلا
                  بناء على مستند رسمي موثق من جهات التوثيق يصدر من المشارك في
                  المزاد خلال (48) ساعة من انتهاء مهلة السداد المعلن عنها في
                  النشرة التسويقية الخاصة بالمزاد، وفي حال عدم تجاوب المشارك في
                  المزاد فتقوم شركة مباشر بالتواصل معه عبر البريد الالكتروني
                  المسجل في منصة مباشر وتطلب منه الموافقة على تحويل العربون
                  لمالك العقار أو لوكيل البيع في حال وجود اتفاق بين مالك العقار
                  ووكيل البيع على ذلك، وفي حال عدم تجاوب المشارك في الرد على
                  البريد الالكتروني فيتم إعادة إرسال البريد الالكتروني لثلاث
                  مرات توضح بأنه في حال عدم الرد فإن العربون سوف يتم مصادرته
                  وتحويله لمالك العقار، ومن ثم تقوم شركة مباشر في البدء بإجراءات
                  التحويل لمالك العقار أو المسوق العقاري بعد استيفاء الخطابات
                  اللازمة بأن مستلم العربون هو من في مواجهة المشارك في حال تمت
                  مطالبة شركة مباشر بإعادة العربون.
                </p>
              </li>
              <li>
                <span className="text-decoration-underline">
                  في حال كان العربون ورقة مالية (سند لأمر):
                </span>
                عند تخلف المشارك الذي فاز بالمزاد عن السداد لأي ظرف خلال مدة
                السداد فإن شركة مباشر سوف تقوم بمطالبة المشارك المتخلف عن السداد
                بقيمة سند لأمر كونه مبلغ مستحق لشركة مباشر، وفي حال عدم تجاوب
                المشارك على تسديد قيمة السند فإن شركة مباشر سوف تحيل المطالبة
                للتنفيذ ويتحمل المنفذ ضدة التكاليف القضائية وأتعاب المحاماة إن
                وجد.
              </li>
            </ol>
          </li>
        </ol>
        <h3>سياسة استرداد الرسوم الإدارية</h3>
        <p>
          كافة الرسوم الإدارية غير مُستردة سواء كانت رسوم إنشاء سند لأمر أو رسوم
          إصدار فاتورة سداد وتم توضيح ذلك في خانة شحن المحفظة, عدا الحالات
          الاستثنائية (قيام العميل بدفع الرسوم الإدارية وعدم تمكنه من الحصول على
          الخدمة لأسباب تقنية طارئة)
        </p>
        <h3>سياسة استرجاع مبالغ الشراء</h3>
        <p>
          منصة مباشر للمزادات تعتبر مقدم خدمة لجميع البائعين الراغبين في بيع ما
          يملكون من أصول عن طريق منصة مباشر للمزادات، وبالتالي فإن دخول المشارك
          في المزاد على المنصة والموافقة على الشروط والاحكام يعتبر ابرام عقد بين
          البائع والمشتري، وينتج عن هذا العقد أن يتحمل كل طرف ما يصدر منه من
          تصرفات ، وشركة مباشر ممثلة في منصة مباشر للمزادات ليست طرف ثالث في
          العقد وإنما يقتصر عملها بتزويد الخدمة التقنية، ومسؤولية صلاح المبيع أو
          عيوبه تعود على الشركة أو الفرد الذي أدرج المزاد.
        </p>
        <ol>
          <li>
            <span className="fw-bold">حال وجود معاينة في المزاد:</span>
            <p>
              يتوجب على المشارك التوجه لموقع المزاد للاطلاع على ما يرغب المشاركة
              فيه ويعاينه المعاينة النافية للجهالة، وتكون مشاركته في المزاد هي
              إقرار منه برغبته في شراء المنتج أو الأصل بحالته الراهنة، ولا يحق
              له التراجع عن الشراء.
            </p>
          </li>
          <li>
            <span className="fw-bold">حال عدم وجود معاينة في المزاد:</span>
            <p>
              تلجأ بعض الشركات والجهات الحكومية لعرض ما لديها من أصول للبيع دون
              معاينة لاحترازات أمنيه أو لسلامة بقية الأصول من العبث أو لأي سبب
              تراه جهة الإدارة، ويكتب في النشرة الخاصة بالمزاد بعدم إمكانية
              المعاينة، وبالتالي فإن البيع على أساس الصور الملتقطة لتلك الأصول
              والبيانات التي قد{" "}
              <span className="text-decoration-underline">
                تحتمل الخطأ والصواب
              </span>
              , ويتم نشر المزاد وبيعه دون حق للمشارك في المعاينة (البيع بالحالة
              الراهنة) وقد يعتري المعروض للمزاد عدة ظروف ،وبالتالي فإن المشارك
              في المزاد على علم بأن البيع لتلك الأصول قد يعتريه نقص أو عيب ولم
              يتم توضيحه ويشتري الأصل على حالته الموصوفة في الصور و البيانات،
              ولا يحق له الرجوع على البائع في حال وجود عيوب بالأصل المباع أو
              اختلاف في البيانات.
            </p>
          </li>
        </ol>
        <p>
          بعد إتمام الصفقة وترسية المبيع ( المملوك للجهة البائعة) على المشارك
          فيتم إصدار فاتورة سداد عن طريق (إيداعات) وترسل للمشتري لسداد القيمة
          كاملة، ويكون السداد إلى حساب شركة مباشر، وتقوم بدورها بتحويل قيمة
          المبيع للشركة البائعة حتى يتم استكمال إجراءات التسليم، وبالتالي فإن
          دور شركة مباشر في عملية السداد هو دور مقدم الخدمة عن طريق إيداعات،
          وتعتبر مشاركة المزايد في المزاد إقرار منه بالسماح لشركة مباشر بتحويل
          مبلغ أو مبالغ الشراء للجهة البائعة وأن شركة مباشر ممثلة في منصتها ليست
          إلا مقدم خدمة وقد أخلت مسئوليتها عن أي مطالبة مالية في حال وجود خلاف
          بين الطرفين أو طلب استرجاع, ويحق للمشتري مطالبة الجهة البائعة في حال
          وجود خلاف، ويقتصر دور شركة مباشر على تقديم معلومات البائع التي تساعد
          المشتري على تقديم الدعوى إن رغب.
        </p>
      </div>
    </>
  );
}
