import React from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
import PrivacyContent from "./privacyContent";

function Privacy() {
  return (
    <>
      <Header />
      <PrivacyContent />
      <Footer />
    </>
  );
}

export default Privacy;
