import React from "react";

import Footer from "../../common/Footer";
import Header from "../../common/Header";
import ReturnContent from "./returnContent";

function Return() {
  return (
    <>
      <Header />
      <ReturnContent />
      <Footer />
    </>
  );
}

export default Return;
